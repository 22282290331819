<template>
  <div class="container">
  </div>
  <div class="profilecard">
    Profil użytkownika
    <img src="..//static/images/img1.png" alt="User" style="width:100%">
    <h2>Nazwisko : {{ currentUser.claims.Name }}
      <br>Imię : {{ currentUser.claims.Surname }}
    </h2>
    <p class="title">Rola {{ currentUser.claims.role }} </p>
    <router-link to="/password" class="btn">Zmień hasło</router-link>
    <!--<p class="title">ID {{ currentUser.claims.id }} </p>
    <p>{{ currentUser.claims.exp }}
    </p>
    
     <router-link to="/password" class="btn">Zmień hasło</router-link>
     <a href="#"><i class="fa fa-dribbble"></i></a>
  <a href="#"><i class="fa fa-twitter"></i></a>
  <a href="#"><i class="fa fa-linkedin"></i></a>
  <a href="#"><i class="fa fa-facebook"></i></a>
   <p><button>{{ currentUser.claims.role }}</button></p> -->
    <router-link to="/password"><button>Zmień hasło</button></router-link>
  </div>
</template>

<script>
export default {
  name: 'Profile',
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    }
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push('/login');
    }
  }
};
</script>
<style scoped>
.profilecard {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  max-width: 300px;
  margin: auto;
  text-align: center;
}

.title {
  color: grey;
  font-size: 18px;
}

button {
  border: none;
  outline: 0;
  display: inline-block;
  padding: 8px;
  color: white;
  background-color: #000;
  text-align: center;
  cursor: pointer;
  width: 100%;
  font-size: 18px;
}

a {
  text-decoration: none;
  font-size: 22px;
  color: black;
}

button:hover,
a:hover {
  opacity: 0.7;
}
</style>